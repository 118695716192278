export default [
  {
    path: '/recruitmentInfo',
    name: 'recruitmentInfo',
    component: () => import('@/views/footerPage/recruitmentInfo.vue')
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import('@/views/footerPage/ourPartners.vue')
  },
  {
    path: '/serviceFeedback',
    name: 'serviceFeedback',
    component: () => import('@/views/footerPage/serviceFeedback.vue')
  },
  {
    path: '/regulatory',
    name: 'regulatory',
    component: () => import('@/views/footerPage/regulatoryPolicies.vue')
  },
  {
    path: '/personalLoans',
    name: 'personalLoans',
    component: () => import('@/views/footerPage/personalLoans.vue')
  },
  {
    path: '/homeLoan',
    name: 'homeLoan',
    component: () => import('@/views/footerPage/homeLoan.vue')
  },
  {
    path: '/affordableLoan',
    name: 'affordableLoan',
    component: () => import('@/views/footerPage/affordableLoan.vue')
  },
  {
    path: '/constructionLoan',
    name: 'constructionLoan',
    component: () => import('@/views/footerPage/constructionLoan.vue')
  },
  {
    path: '/usedCarLoan',
    name: 'usedCarLoan',
    component: () => import('@/views/footerPage/usedCarLoan.vue')
  },
  {
    path: '/twoWheelerLoan',
    name: 'twoWheelerLoan',
    component: () => import('@/views/footerPage/twoWheelerLoan.vue')
  },
  {
    path: '/travelLoan',
    name: 'travelLoan',
    component: () => import('@/views/footerPage/travelLoan.vue')
  },
  {
    path: '/educationLoan',
    name: 'educationLoan',
    component: () => import('@/views/footerPage/educationLoan.vue')
  },
  {
    path: '/medicalLoan',
    name: 'medicalLoan',
    component: () => import('@/views/footerPage/medicalLoan.vue')
  },
  {
    path: '/workingCapital',
    name: 'workingCapital',
    component: () => import('@/views/footerPage/workingCapital.vue')
  },
  {
    path: '/securitiesLoan',
    name: 'securitiesLoan',
    component: () => import('@/views/footerPage/securitiesLoan.vue')
  },
  {
    path: '/termLoan',
    name: 'termLoan',
    component: () => import('@/views/footerPage/termLoan.vue')
  },
  {
    path: '/vehicleInsurance',
    name: 'vehicleInsurance',
    component: () => import('@/views/footerPage/vehicleInsurance.vue')
  },
  {
    path: '/lifeInsurance',
    name: 'lifeInsurance',
    component: () => import('@/views/footerPage/lifeInsurance.vue')
  },
  {
    path: '/healthInsurance',
    name: 'healthInsurance',
    component: () => import('@/views/footerPage/healthInsurance.vue')
  },
  {
    path: '/travelInsurance',
    name: 'travelInsurance',
    component: () => import('@/views/footerPage/travelInsurance.vue')
  },
  {
    path: '/wellnessProgramme',
    name: 'wellnessProgramme',
    component: () => import('@/views/footerPage/wellnessProgramme.vue')
  }

]
