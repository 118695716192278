<template>
  <div class="center">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item style="cursor: pointer;" :to="{ path: '/home' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    }
  }
}
</script>

<style scoped lang="less">
.center {
  padding-left: 60px;
  margin: 30px auto;
}
::v-deep .el-breadcrumb__inner {
  font-size: 22px;
  color: #175CA4 !important;
  font-weight: 700  !important;
}
::v-deep .is-link {
  color: #6C6E73 !important;
  font-weight: normal !important;
}
</style>
