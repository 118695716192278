export default [
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/abuotUs/index.vue'),
    children: [
      {
        path: 'overview',
        name: 'overview',
        component: () => import('@/views/abuotUs/index.vue')
      },
      {
        path: 'keyPeople',
        name: 'keyPeople',
        component: () => import('@/views/abuotUs/index.vue')
      },
      {
        path: 'alliances',
        name: 'alliances',
        component: () => import('@/views/abuotUs/index.vue')
      },
      {
        path: 'awards',
        name: 'awards',
        component: () => import('@/views/abuotUs/index.vue')
      },
      {
        path: 'ourServices',
        name: 'ourServices',
        component: () => import('@/views/abuotUs/index.vue')
      }
    ]
  }
]
