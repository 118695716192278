import Vue from 'vue'
import VueRouter from 'vue-router'
import aboutUs from './aboutUs'
import footerPage from './footerPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue')
  }
].concat(aboutUs).concat(footerPage)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // 平滑滚动
  })
  next()
})

export default router
