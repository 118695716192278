import Vue from 'vue'
import { Menu, MenuItem, Submenu, Input, Select, Option, Icon, Tabs, TabPane, Breadcrumb, BreadcrumbItem, Radio, RadioGroup, Collapse, CollapseItem, Dialog } from 'element-ui'

Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dialog)
