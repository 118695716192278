<template>
  <div>
    <div class="footer">
      <div class="footerItems">
        <div class="footerItem">
          <h5>About Us</h5>
          <div class="text">
            <p><span @click="toPage('overview')">About Us</span></p>
            <p><span>Service Purpose</span></p>
            <p><span @click="toPage('recruitmentInfo')">Recruitment Information</span></p>
            <p><span @click="toPage('partners')">Partners</span></p>
            <p><span @click="toPage('serviceFeedback')">Service Feedback</span></p>
            <p><span @click="toPage('regulatory')">Regulatory Policies</span></p>
            <p><span>Anti-bribery & Anti-corruption Policy</span></p>
            <p><span>KYC Policy</span></p>
            <p><span>Legal Disclaimer</span></p>
            <p><span>Privacy Commitment</span></p>
            <p><span></span></p>
          </div>
        </div>
        <div class="footerItem">
          <h5>Recommended Products</h5>
          <div class="text">
            <p><span @click="toPage('personalLoans')">Personal Loans</span></p>
            <p><span @click="toPage('homeLoan')">Home Extension Loan</span></p>
            <p><span @click="toPage('affordableLoan')">Affordable Housing Loan</span></p>
            <p><span @click="toPage('constructionLoan')">Home Construction Loan</span></p>
            <p><span @click="toPage('usedCarLoan')">Used Car Loan</span></p>
            <p><span @click="toPage('twoWheelerLoan')">Two Wheeler Loan</span></p>
            <p><span @click="toPage('travelLoan')">Travel Loan</span></p>
            <p><span @click="toPage('workingCapital')">Working Capital Loan</span></p>
            <p><span @click="toPage('vehicleInsurance')">Motor Vehicle Insurance</span></p>
            <p><span @click="toPage('healthInsurance')">Health Insurance</span></p>
          </div>
        </div>
        <div class="footerItem">
          <h5>Calculator</h5>
          <div class="text">
            <p><span>Personal Loan Calculator</span></p>
            <p><span>Home Loan Calculator</span></p>
          </div>
        </div>
        <div class="footerItem">
          <h5>Calculator</h5>
          <div class="text">
            <div class="info">Email: <span>support@grjtradefinance.com</span></div>
            <div class="info">
              Registered Office:
              <span>
                24A, Shakespeare Sarani, Parijaat Building, 2nd floor, Room No.22/5A, , KOLKATA, West Bengal, India - 700017.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="batchNumber">
      <div class="text">All Rights Reserved © 2024 ****</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerPage',
  methods: {
    toPage (name) {
      if (this.$route.name === name) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 平滑滚动
        })
      } else {
        this.$router.replace({ name })
      }
    }
  }
}
</script>

<style scoped lang="less">
.footer {
  height: 500px;
  background-color: #0C2F53;
  border-bottom: 1px solid #FFFFFF;
  position: relative;
  z-index: 2;
  padding: 110px 0;
  .footerItems {
    width: 1400px;
    margin: 0 auto;
    display: flex;
  }
  .footerItem {
    width: 25%;
    text-align: left;
    h5 {
      font-size: 16px;
      color: #fff;
      margin-bottom: 26px;
    }
    .text {
      p {
        color: rgba(255, 255, 255, 0.50);
        font-size: 14px;
        margin-bottom: 7px;
        span {
          cursor: pointer;
          &:hover {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
      .info {
        color: rgba(255, 255, 255, 0.50);
        font-size: 14px;
        margin-bottom: 7px;
        span {
          color: #fff;
        }
      }
    }
  }
}
.batchNumber {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #0C2F53;
  color: #CCCCCC;
  font-size: 14px;
}
</style>
